// src/components/popup/addproject/AddProjectPopup.js
import React, { useState, useEffect } from "react";
import { ProjectService } from "../../../../domain/services/ProjectService";
import FirebaseProjectRepository from "../../../../data/repository/FirebaseProjectRepository";
import { v4 as uuidv4 } from "uuid";
import { db, auth } from "../../../../framework/firebase";
import { ref, set, update } from "firebase/database";

const projectService = new ProjectService(new FirebaseProjectRepository());

const AddProjectPopup = ({ project, onClose, onAdd }) => {
  const [nombre, setNombre] = useState("");
  const [dominio, setDominio] = useState("");
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    if (project) {
      setNombre(project.name);
      setDominio(project.domain);
      setApiKey(project.apiKey);
    }
  }, [project]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = auth.currentUser.uid;

    if (project) {
      // Si 'project' está definido, estamos editando
      const updatedProject = {
        ...project,
        name: nombre,
        domain: dominio,
        apiKey: apiKey,
      };

      try {
        // Actualizar el proyecto existente
        await update(
          ref(db, `users/${userId}/projects/${project.id}`),
          updatedProject
        );
        onAdd(updatedProject);
        onClose();
      } catch (error) {
        console.error("Hubo un error al actualizar el proyecto:", error);
      }
    } else {
      const projectId = uuidv4();
      const newProject = {
        id: projectId,
        name: nombre,
        domain: dominio,
        apiKey: apiKey,
        ownerId: userId,
      };

      try {
        await set(ref(db, `users/${userId}/projects/${projectId}`), newProject);
        onAdd(newProject);
        onClose();
      } catch (error) {
        console.error("Hubo un error al añadir el proyecto:", error);
      }
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          <h2>Crear Proyecto</h2>
          <label>
            Nombre del Proyecto:
            <input
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </label>
          <label>
            Dominio:
            <input
              type="text"
              value={dominio}
              onChange={(e) => setDominio(e.target.value)}
              required
            />
          </label>
          <label>
            API Key:
            <input
              type="text"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              required
            />
          </label>
          <div className="buttons-container">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancelar
            </button>
            <button type="submit" className="cta-button">
              {project ? "Actualizar proyecto" : "Crear proyecto"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProjectPopup;

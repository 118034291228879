// src/data/repositories/FirebaseProjectRepository.js
import { ref, get, push, update, remove, set } from "firebase/database";
import { Project } from "../../domain/project/model/Project"; // Importar tu modelo de Project

export default class FirebaseProjectRepository {
  constructor(db) {
    this.db = db;
  }

  // Obtener todos los proyectos
  async getProjects() {
    const snapshot = await get(ref(this.db, `projects`));
    if (snapshot.exists()) {
      const data = snapshot.val();
      const projectsArray = Object.entries(data).map(([id, projectData]) => {
        return new Project(
          id,
          projectData.name,
          projectData.domain,
          projectData.apiKey,
          projectData.keywords || [] // Si no hay keywords, se pasa un array vacío
        );
      });
      return projectsArray; // Devuelve un array de instancias de Project
    } else {
      return []; // Devuelve un array vacío si no hay proyectos
    }
  }

  // Obtener un proyecto por ID
async getProjectById(userId, projectId) {
  if (!userId || !projectId) {
    throw new Error("Invalid userId or projectId");
  }

  const projectRef = ref(this.db, `users/${userId}/projects/${projectId}`);
  const snapshot = await get(projectRef);

  if (snapshot.exists()) {
    const projectData = snapshot.val();
    return new Project(
      projectId,
      projectData.name,
      projectData.domain,
      projectData.apiKey,
      projectData.keywords || [] // Si no hay keywords, se pasa un array vacío
    );
  } else {
    throw new Error("Proyecto no encontrado");
  }
}



  // Agregar un nuevo proyecto
  async addProject(project) {
    try {
      const projectRef = ref(this.db, `projects`);
      const newProjectRef = push(projectRef);

      await set(newProjectRef, {
        name: project.name,
        domain: project.domain,
        apiKey: project.apikey,
        keywords: project.keywords, // Guardar las keywords si existen
      });

      return { message: "Proyecto creado con éxito", projectId: newProjectRef.key };
    } catch (error) {
      console.error("Error adding project: ", error);
      throw new Error("Failed to add project");
    }
  }

  // Actualizar un proyecto existente
  async updateProject(project) {
    try {
      const projectRef = ref(this.db, `projects/${project.id}`);
      const snapshot = await get(projectRef);

      if (!snapshot.exists()) throw new Error("Proyecto no encontrado");

      const updates = {
        name: project.name,
        domain: project.domain,
        apiKey: project.apikey,
        keywords: project.keywords, // Actualizar las keywords también
        updatedDate: new Date().toISOString(), // Marcar la fecha de actualización
      };

      await update(projectRef, updates);
      return { message: "Proyecto actualizado con éxito" };
    } catch (error) {
      console.error("Error updating project: ", error);
      throw new Error("Failed to update project");
    }
  }

  // Eliminar un proyecto
  async deleteProject(uid, projectId) {
    if (!projectId) {
      throw new Error("Invalid projectId");
    }

    const projectRef = ref(this.db, `users/${uid}/projects/${projectId}`);
    await remove(projectRef);
    return { message: "Proyecto eliminado con éxito" };
  }
}

// src/context/ProjectContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getProjects } from '../../framework/DependencyInjector';

const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);

    const fetchProjects = async () => {
        try {
            const response = await getProjects.execute();
            setProjects(response.data || []);
        } catch (error) {
            console.error('Error fetching projects:', error);
            setProjects([]); // Establecer como vacío en caso de error
        }
    };

    // Puedes llamar a fetchProjects en un efecto, o desde cualquier componente que necesite actualizar la lista
    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <ProjectContext.Provider value={{ projects, setProjects }}>
            {children}
        </ProjectContext.Provider>
    );
};

const useProjects = () => React.useContext(ProjectContext);

export { ProjectProvider, useProjects };

// src/hooks/UseKeywords.js
import { useState, useEffect } from "react";
import { getKeywordsByProjectIdUseCase } from '../../framework/DependencyInjector'; // Importa el nuevo caso de uso

export const useKeywords = (uid, projectId) => {
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const keywordsData = await getKeywordsByProjectIdUseCase.execute(uid, projectId);
        setKeywords(keywordsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchKeywords();
  }, [projectId]);

  const refreshKeywords = async () => {
    try {
      const keywordsData = await getKeywordsByProjectIdUseCase.execute(uid, projectId);
      setKeywords(keywordsData);
    } catch (err) {
      setError(err.message);
    }
  };

  return { keywords, loading, error, refreshKeywords };
};

// src/domain/usecases/AddKeywords.js
import { mapToProject } from "../../project/model/ProjectMapper";
class AddKeywords {
  constructor(keywordRepository) {
    this.keywordRepository = keywordRepository;
  }

  async execute(uid, project, keywords) {

    await this.keywordRepository.addKeywords(uid, mapToProject(project), keywords);
  }
}

export default AddKeywords;
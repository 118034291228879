//src\domain\project\usecases\GetProjectByIdUseCase.js
class GetProjectByIdUseCase {
  constructor(projectRepository) {
    this.projectRepository = projectRepository;
  }

  async execute(uid, projectId) {
    if (!projectId) {
      throw new Error('Invalid projectId');
    }

    return  Object.values(await this.projectRepository.getProjectById(uid, projectId));
  }
}

export default GetProjectByIdUseCase;

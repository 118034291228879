class UpdateKeywords {
  constructor(keywordRepository) {
    this.keywordRepository = keywordRepository;
  }

  async execute(uid) {

    await this.keywordRepository.updateKeywordsForAllProjects(uid);
  }
}

export default UpdateKeywords;
// src/framework/firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth"; // Importar getAuth

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBe6FHaaT5Gv_QbVNBiVKyvPAEtCXQopmQ",
  authDomain: "turion-ranker.firebaseapp.com",
  databaseURL: "https://turion-ranker-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "turion-ranker",
  storageBucket: "turion-ranker.appspot.com",
  messagingSenderId: "575438452809",
  appId: "1:575438452809:web:43f562d30d1206fc102798",
  measurementId: "G-74ZGXTMG8F"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app); // Inicializar Auth

export { db, auth };

class CalculateAveragePositionUseCase {
  execute(keywords) {
    if (!keywords) {
      return 101;
    }

    const values = Object.values(keywords);
    const totalPosition = values.reduce((sum, keyword) => sum + keyword.currentPosition, 0);
    return Math.round(totalPosition / values.length);
  }
}

export default CalculateAveragePositionUseCase;

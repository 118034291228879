class DeleteProjectUseCase {
  constructor(projectRepository) {
    this.projectRepository = projectRepository;
  }

  async execute(uid, projectId) {
    if (!projectId) {
      throw new Error('Invalid projectId');
    }

    await this.projectRepository.deleteProject(uid, projectId);
  }
}

export default DeleteProjectUseCase;
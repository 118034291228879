export class ProjectService {
    constructor(projectRepository) {
      this.projectRepository = projectRepository;
    }
  
    async addProject(project) {
      return this.projectRepository.addProject(project);
    }
  
    async getProjects() {
      return this.projectRepository.getProjects();
    }
  }
  
//src/components/keywordblock/KeywordBlock.js
import React from "react";
import "./KeywordBlock.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const KeywordBlock = ({ totalKeywords, onAddClick }) => {
  const safeTotalKeywords = totalKeywords ?? 0;
  return (
    <div className="keyword-block">
      <h3>Palabras Clave</h3>
      <div className="circle-container">
        <CircularProgressbar
          value={safeTotalKeywords}
          text={`${safeTotalKeywords}`}
          styles={buildStyles({
            pathColor: "#12B7FC",
            trailColor: "#d6d6d6",
            textColor: "#12B7FC",
            pathTransitionDuration: 0.5,
          })}
        />
        <div className="keyword-label">Keywords</div>
      </div>
      <div className="keyword-buttons">
        <button className="add-button" onClick={onAddClick}>
          Añadir
        </button>
        <button className="import-button">Importar CSV</button>
      </div>
    </div>
  );
};

export default KeywordBlock;

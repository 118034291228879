import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import logo from "./../../../assets/images/turion-logo.webp";
import { getAuth, signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const navigate = useNavigate(); // Reemplaza useHistory con useNavigate

  const handleLogout = async () => {
    const auth = getAuth(); // Inicializa la autenticación
    try {
      await signOut(auth);
      navigate("/"); // Usa navigate en lugar de history.push para redirigir
    } catch (error) {
      console.error("Error al cerrar sesión: ", error);
    }
  };

  return (
    <header className="project-header">
      <nav className="menu">
        <ul>
          <li>
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </li>
          <li>
            <Link to="/">Proyectos</Link>
          </li>
          <li>
            <a href="#about">Configuración</a>
          </li>
          <li>
            <a href="#services">Ayuda</a>
          </li>
          <button
            onClick={handleLogout}className="logout-button">
            <FontAwesomeIcon icon={faPowerOff} color="#12B7FC" />
          </button>
        </ul>
      </nav>
      <div className="extra-section">
        {/* Pueden ir botones, íconos o cualquier otra cosa */}
      </div>
    </header>
  );
};

export default Header;

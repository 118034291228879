import { Project } from "./Project";

export function mapToProject(data) {
  return new Project(
    data[0],        // id
    data[1],        // name
    data[2],        // domain
    data[3],        // apikey
    data[4] || {}   // keywords (si no existe, lo inicializa como un objeto vacío)
  );
}

// src/App.js
import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ProjectList from './ui/views/projectlist/ProjectList';
import ProjectDetail from './ui/views/projectdetail/ProjectDetails';
import Login from './ui/views/login/Login';
import { ProjectProvider } from './ui/context/ProjectContext';
import { auth } from './framework/firebase';
import { onAuthStateChanged } from 'firebase/auth';

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe(); // Limpiar la suscripción
    }, []);

    return (
        <ProjectProvider>
            <Router>
                <Routes>
                    <Route path="/" element={user ? <ProjectList /> : <Login />} />
                    <Route path="/proyecto/:id" element={user ? <ProjectDetail /> : <Navigate to="/" />} />
                </Routes>
            </Router>
        </ProjectProvider>
    );
}

export default App;

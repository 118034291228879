class GetKeywordsByProjectId {
  constructor(keywordRepository) {
    this.keywordRepository = keywordRepository;
  }

  async execute(uid, projectId) {
    if (!projectId) {
      throw new Error('Invalid projectId');
    }

    return  Object.values(await this.keywordRepository.getKeywordsByProjectId(uid, projectId));
  }
}

export default GetKeywordsByProjectId;

export class Project {
  constructor(id, name, domain, apikey, keywords = []) {
    this.id = id;
    this.name = name;
    this.domain = domain;
    this.apikey = apikey;
    this.keywords = keywords;
  }
}

function mapToProject(data) {
  return new Project(
    data[0],        // id
    data[1],        // name
    data[2],        // domain
    data[3],        // apikey
    data[4] || {}   // keywords (si no existe, lo inicializa como un objeto vacío)
  );
}
import React, { useState, useEffect } from "react";
import StatItem from "./StatItem";
import "./KeywordStats.css";
import {
  faRankingStar,
  faCrown,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import CalculateAveragePositionUseCase from "../../../domain/keyword/usecases/CalculateAveragePositionUseCase";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const generateChart = (data) => (
  <Line
    data={{
      labels: Array.from({ length: 30 }, (_, i) => `Día ${i + 1}`),
      datasets: [
        {
          label: "Estadística",
          data: data,
          borderColor: "rgba(3, 138, 255, 1)",
          backgroundColor: "rgba(3, 138, 255, 1)",
          borderWidth: 2,
          fill: true,
          tension: 0.3,
        },
      ],
    }}
    options={{
      scales: {
        x: {
          type: "category",
          beginAtZero: true,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }}
    height={25}
    width={100}
  />
);

const calculateAveragePosition = (keywords) => {
  const averagePositionUseCase = new CalculateAveragePositionUseCase();
  return averagePositionUseCase.execute(keywords);
};

const categorizeKeywords = (keywords) => {
  const lists = {
    list1: [],
    list_2_3: [],
    list_4_10: [],
    list_11_30: [],
    list_31_100: [],
  };

  if (!keywords.isEmpty) {
    keywords.forEach((keyword) => {
      if (keyword.currentPosition === 1) {
        lists.list1.push(keyword.keyword);
      } else if ([2, 3].includes(keyword.currentPosition)) {
        lists.list_2_3.push(keyword.keyword);
      } else if (
        keyword.currentPosition >= 4 &&
        keyword.currentPosition <= 10
      ) {
        lists.list_4_10.push(keyword.keyword);
      } else if (
        keyword.currentPosition >= 11 &&
        keyword.currentPosition <= 30
      ) {
        lists.list_11_30.push(keyword.keyword);
      } else if (
        keyword.currentPosition >= 31 &&
        keyword.currentPosition <= 100
      ) {
        lists.list_31_100.push(keyword.keyword);
      }
    });
  }

  return lists;
};

const KeywordStats = ({ changes, keywords }) => {
  const avgPosition = calculateAveragePosition(keywords);
  const { list1, list_2_3, list_4_10, list_11_30, list_31_100 } =
    categorizeKeywords(keywords);

  const defaultChange = {
    rank: 0,
    pos1: 0,
    pos2_3: 0,
    pos4_10: 0,
    pos11_30: 0,
    pos31_100: 0,
  };

  const firstChange = changes[0] || defaultChange;

  const statItems = [
    {
      icon: faRankingStar,
      classIcon: "rank",
      title: "Posición Media",
      value: avgPosition,
      trend: avgPosition - firstChange.rank,
      data: changes.map((change) => change.rank),
    },
    {
      icon: faCrown,
      classIcon: "top1",
      title: "TOP 1",
      value: list1.length,
      trend: list1.length - firstChange.pos1,
      data: changes.map((change) => change.pos1),
    },
    {
      icon: faMedal,
      classIcon: "top2",
      title: "TOP 2-3",
      value: list_2_3.length,
      trend: list_2_3.length - firstChange.pos2_3,
      data: changes.map((change) => change.pos2_3),
    },
    {
      icon: faMedal,
      classIcon: "top4",
      title: "TOP 4-10",
      value: list_4_10.length,
      trend: list_4_10.length - firstChange.pos4_10,
      data: changes.map((change) => change.pos4_10),
    },
    {
      icon: faMedal,
      classIcon: "top11",
      title: "TOP 11-30",
      value: list_11_30.length,
      trend: list_11_30.length - firstChange.pos11_30,
      data: changes.map((change) => change.pos11_30),
    },
    {
      icon: faMedal,
      classIcon: "top31",
      title: "TOP 31-100",
      value: list_31_100.length,
      trend: list_31_100.length - firstChange.pos31_100,
      data: changes.map((change) => change.pos31_100),
    },
  ];

  return (
    <div className="keyword-stats">
      {statItems.map((item, index) => (
        <StatItem
          key={index}
          icon={item.icon}
          classIcon={item.classIcon}
          title={item.title}
          value={String(item.value)}
          trend={isNaN(item.trend) ? "N/A" : String(item.trend)}
          chart={generateChart(item.data)}
        />
      ))}
    </div>
  );
};

export default KeywordStats;

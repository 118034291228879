// src/components/select/SelectComponent.js
import React from 'react';
import './SelectComponent.css';

const SelectComponent = ({ options, classNameItem, selectedValue, onSelectChange }) => {
  return (
    <select
      className={classNameItem}
      value={selectedValue}
      onChange={(e) => onSelectChange(e.target.value)}
      style={{
        fontSize: selectedValue ? "1.5em" : "1em",
      }}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          style={{ fontSize: "14px" }}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectComponent;
import React from "react";
import "./ActionsBar.css";

const ActionsBar = ({ handleBatchAction, handleTagFilter, handleSearch }) => {
  return (
    <div className="actions-bar">
      <select
        className="batch-actions"
        onChange={(e) => handleBatchAction(e.target.value)}
      >
        <option value="">Acciones por lote</option>
        <option value="delete">Eliminar seleccionados</option>
        <option value="export">Exportar seleccionados</option>
      </select>

        <select
          className="filter-tags"
          onChange={(e) => handleTagFilter(e.target.value)}
        >
          <option value="">Etiquetas</option>
          <option value="bafang">Bafang</option>
          <option value="other">Other</option>
        </select>

        <input
          type="text"
          className="keyword-search"
          placeholder="Buscar keyword..."
          onChange={(e) => handleSearch(e.target.value)}
        />
  
    </div>
  );
};

export default ActionsBar;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ProjectDetail.css";
import SelectComponent from "../../components/select/SelectComponent";
import AddKeywordPopup from "../../components/popup/addkeyword/AddKeywordPopup";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import KeywordStats from "../../components/stats/KeywordStats";
import KeywordTable from "../../components/table/KeywordTable";
import KeywordBlock from "../../components/keywordblock/KeywordBlock";
import Header from "../../components/header/Header";
import { getProjectByIdUseCase } from "../../../framework/DependencyInjector";
import { useKeywords } from "../../hooks/UseKeywords";
import { useChanges } from "../../hooks/UseChanges";
import { auth } from "../../../framework/firebase";
import { useProjects } from '../../context/ProjectContext';

const ProjectDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { projects } = useProjects();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const {
    keywords,
    loading: keywordsLoading,
    error: keywordsError,
    refreshKeywords,
  } = useKeywords(auth.currentUser.uid, id);
  const {
    changes,
    loading: changesLoading,
    error: changesError,
    refreshChanges,
  } = useChanges(id);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectData = await getProjectByIdUseCase.execute(auth.currentUser.uid, id);
        if (projectData) {
          setProject({ ...projectData, id });
          setSelectedProjectId(id);
        } else {
          console.error("Project data is null");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching project details:", error);
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  useEffect(() => {
    if (!keywordsLoading) {
      setLoading(false);
    }
  }, [keywordsLoading]);

  if (loading || keywordsLoading) return <LoadingSpinner />;

  if (!project) return <div>Project not found</div>;

  const currentDate = new Date().toLocaleDateString();

  const handleSelectChange = (value) => {
    navigate(`/proyecto/${value}`);
  };

  const projectOptions = projects.map((project) => ({
    value: project.id,
    label: project.domain
  }));

  return (
    <div className="project-detail">
      <Header />
      <div>
        <div className="content-project">
          <SelectComponent
            options={projectOptions}
            classNameItem={"select"}
            selectedValue={selectedProjectId}
            onSelectChange={handleSelectChange}
          />
          <p className="last-connection">Última actualización: {currentDate}</p>
        </div>
      </div>
      <div className="content">
        <div className="stats-and-keywords">
          <KeywordStats changes={changes} keywords={keywords} />
          <KeywordBlock
            totalKeywords={keywords.length}
            onAddClick={openPopup}
          />
        </div>
        {isPopupOpen && (
          <AddKeywordPopup
            onKeywordsAdded={refreshKeywords}
            onClose={closePopup}
            project={project}
          />
        )}
        <div className="keyword-table-detail">
          <KeywordTable
            projectId={id}
            keywords={keywords}
            onKeywordDeleted={refreshKeywords}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;

// src/utils/csvHandler.js
import Papa from 'papaparse';

export const parseCSVFile = (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            complete: (results) => {
                const keywords = results.data.map(row => row[0].trim());
                resolve(keywords);
            },
            error: (error) => reject(error),
        });
    });
};

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowUp, faArrowDown, faTrash, faPencil,} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AddProjectPopup from "../../popup/addproject/AddProjectPopup";
import "./ProjectItem.css";

const getDifferenceDisplay = (change) => {
  let className = "";
  let changeIcon = null;

  if (change > 0) {
    className = "negative-diff";
    changeIcon = faArrowDown;
  } else if (change < 0) {
    className = "positive-diff";
    changeIcon = faArrowUp;
  }

  return { className, changeIcon };
};

const ProjectItem = ({ project, value, trend, onDelete,  isLoading }) => {
  const [showEditPopup, setShowEditPopup] = useState(false); // Controla el estado del popup

  const handleDelete = () => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este proyecto?")) {
      onDelete(project.id); // Llama a la función de eliminación
    }
  };

  const { className, changeIcon } = getDifferenceDisplay(trend);

  return (
    <>
      <div className="data">
        <Link className="link-to-project" to={`/proyecto/${project.id}`}>
          <h3 className="title">{project.name}</h3>
          <p className="subtitle">POSICIÓN MEDIA</p>
          <div className="info">
            <p className="stat-value">{value}</p>

            {isLoading ? (
              <p className="loading">Actualizando...</p>
            ) : (
              <p className={className}>
                {changeIcon && <FontAwesomeIcon icon={changeIcon} />} {Math.abs(trend)}
              </p>
            )}
          </div>
        </Link>
        <div className="actions">
          <button onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button onClick={() => setShowEditPopup(true)}>
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>
      </div>

      {showEditPopup && (
        <AddProjectPopup
          project={project} // Pasa el proyecto al popup
          onClose={() => setShowEditPopup(false)}
          onAdd={(updatedProject) => {
            // Maneja la actualización o creación del proyecto
            console.log("Proyecto actualizado o añadido:", updatedProject);
            setShowEditPopup(false);
          }}
        />
      )}
    </>
  );
};

export default ProjectItem;

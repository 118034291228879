import React, { useState, useEffect } from "react";
import AddProjectPopup from "../../components/popup/addproject/AddProjectPopup";
import Header from "../../components/header/Header";
import "./ProjectList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import ProjectItem from "../../components/cards/projects/ProjectItem";
import { useProjects } from "../../context/ProjectContext";
import { deleteProjectUseCase } from "../../../framework/DependencyInjector";
import { updateKeywordsUseCase } from "../../../framework/DependencyInjector";
import CalculateAveragePositionUseCase from "../../../domain/keyword/usecases/CalculateAveragePositionUseCase";
import { db, auth } from "../../../framework/firebase";
import { ref, onValue } from "firebase/database";

const ProjectList = () => {
  const { setProjects } = useProjects();
  const [localProjects, setLocalProjects] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState({});
  const [showUpdateButton, setShowUpdateButton] = useState(true);


  useEffect(() => {
    if (auth.currentUser) {
      fetchProjects();
    }
  }, []);

  const fetchProjects = async () => {
    const userId = auth.currentUser.uid;
    const projectsRef = ref(db, `users/${userId}/projects`);
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const projectsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setLocalProjects(projectsArray);
        setProjects(projectsArray);
        const today = new Date().toLocaleDateString();
        const allUpdated = projectsArray.every(project => project.lastUpdate === today);
        setShowUpdateButton(!allUpdated);
      
      } else {
        setLocalProjects([]);
        setProjects([]);
        setShowUpdateButton(false);
      }
    });
  };

  const handleUpdateKeywords = async () => {
    if (auth.currentUser) {
      setLoadingProjects((prevState) => {
        const newState = {};
        localProjects.forEach((project) => {
          newState[project.id] = true; // Establece cada proyecto como cargando
        });
        return newState;
      });

      try {
        await updateKeywordsUseCase.execute(auth.currentUser.uid);
        fetchProjects(); // Vuelve a obtener los proyectos para actualizar el estado
      } catch (error) {
        console.error("Error updating keywords:", error);
      } finally {
        setLoadingProjects({}); // Restablece el estado de carga
      }
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProjectUseCase.execute(auth.currentUser.uid, projectId);
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const calculateAveragePosition = (keywords) => {
    const averagePositionUseCase = new CalculateAveragePositionUseCase();
    return averagePositionUseCase.execute(keywords);
  };

  return (
    <div>
      <Header />
      <div className="project-info">
        <div className="content">
          <div className="header-message">
            <p className="subheader-title">
              ESTÁS A UNA WEB DE VIVIR DE TUS PROYECTOS
            </p>
            <p className="subheader-subtitle">
              {localProjects.length === 0
                ? "NO SEAS MIERDAS Y EMPIEZA YA!"
                : `No bajes el ritmo, que ya tienes ${localProjects.length} webs más que los demás`}
            </p>
          </div>

          {showUpdateButton && (
            <button onClick={handleUpdateKeywords} className="update-button">
              Actualizar proyectos
            </button>
          )}

          <button onClick={togglePopup} className="cta-button">
            <FontAwesomeIcon icon={faAdd} /> AÑADIR PROYECTO
          </button>
        </div>
      </div>
      <div className="content">
        {showPopup && (
          <AddProjectPopup onClose={togglePopup} onAdd={fetchProjects} />
        )}

        <div className="projects-item">
          {localProjects.map((project) => (
            <ProjectItem
              key={project.id}
              project={project}
              value={calculateAveragePosition(project.keywords)}
              trend={2}
              onDelete={handleDeleteProject}
              isLoading={loadingProjects[project.id] || false} // Pasa el estado de carga

            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectList;

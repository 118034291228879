// src/data/repositories/FirebaseKeywordRepository.js
import { ref, get} from "firebase/database";

export default class ChangesRepository {
  constructor(db) {
    this.db = db;
  }

  async getChangesByProjectId(projectId) {
    const snapshot = await get(ref(this.db, `projects/${projectId}/changes`));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return {}; // Cambia esto a un objeto vacío si no hay keywords
    }
  }
}

import React, { useState } from "react";
import "./KeywordTable.css";
import ActionsBar from "./ActionsBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTag,
  faArrowUp,
  faArrowDown,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import { deleteKeywordUseCase } from "../../../framework/DependencyInjector"; // Importa el caso de uso
import { auth } from "../../../framework/firebase";

const KeywordTable = ({ projectId, keywords, onKeywordDeleted }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "Keyword",
    direction: "ascending",
  });

  const safeKeywords = Array.isArray(keywords) ? keywords : [];

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedKeywords = [...safeKeywords].sort((a, b) => {
    const valueA =
      typeof a[sortConfig.key] === "number"
        ? a[sortConfig.key]
        : parseFloat(a[sortConfig.key]);
    const valueB =
      typeof b[sortConfig.key] === "number"
        ? b[sortConfig.key]
        : parseFloat(b[sortConfig.key]);

    if (valueA < valueB) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "";
  };

  const handleDelete = async (id) => {
    try {
      await deleteKeywordUseCase.execute(auth.currentUser.uid, projectId, id);
      onKeywordDeleted(id); // Callback para actualizar la UI
    } catch (error) {
      console.error(`Error eliminando la keyword con id: ${id}`, error);
    }
  };
  const handleTag = (id) => {};

  const handleBatchAction = (action) => {
  };

  const handleTagFilter = (tag) => {
  };

  const handleSearch = (searchTerm) => {
  };

  const getDifferenceDisplay = (currentPosition, previousPosition) => {
    let className = "";
    let changeIcon = null;

    if (currentPosition < previousPosition) {
      className = "positive-diff";
      changeIcon = faArrowUp;
    } else if (currentPosition > previousPosition) {
      className = "negative-diff";
      changeIcon = faArrowDown;
    }
    return { className, changeIcon };
  };

  const isBestPosition = (currentPosition, bestPosition, lastChangeDate) => {
    let bestPositionIcon = null;
    if (
      currentPosition == bestPosition &&
      lastChangeDate == new Date().toLocaleDateString() &&
      currentPosition < 101
    ) {
      bestPositionIcon = faMedal;
    }
    return { bestPositionIcon };
  };

  return (
    <div>
      <ActionsBar
        handleBatchAction={handleBatchAction}
        handleTagFilter={handleTagFilter}
        handleSearch={handleSearch}
      />
      <table className="keyword-table">
        <thead>
          <tr>
            <th onClick={() => requestSort("keyword")} className="first-column">
              Keyword {getSortDirection("keyword")}
            </th>
            <th onClick={() => requestSort("currentPosition")}>
              Posición {getSortDirection("currentPosition")}
            </th>
            <th onClick={() => requestSort("change")}>
              Cambio {getSortDirection("change")}
            </th>
            <th onClick={() => requestSort("previousPosition")}>
              Anterior {getSortDirection("previousPosition")}
            </th>
            <th onClick={() => requestSort("bestPosition")}>
              Mejor {getSortDirection("bestPosition")}
            </th>
            <th onClick={() => requestSort("initialPosition")}>
              Inicial {getSortDirection("initialPosition")}
            </th>
            <th onClick={() => requestSort("lastChangeDate")}>
              Fecha último cambio {getSortDirection("lastChangeDate")}
            </th>
            <th onClick={() => requestSort("Etiqueta")}>
              Etiqueta {getSortDirection("Etiqueta")}
            </th>
          </tr>
        </thead>
        <tbody>
          {safeKeywords.length > 0 ? (
            sortedKeywords.map((keyword) => {
              const { className, changeIcon } = getDifferenceDisplay(
                keyword.currentPosition, keyword.previousPosition
              );

              const { bestPositionIcon } = isBestPosition(
                keyword.currentPosition,
                keyword.bestPosition,
                keyword.lastChangeDate
              );
              return (
                <tr key={keyword.keyword}>
                  <td className="first-column">{keyword.keyword}</td>
                  <td>
                    <span>
                      {keyword.currentPosition}{" "}
                      {bestPositionIcon && (
                        <FontAwesomeIcon icon={bestPositionIcon} />
                      )}
                    </span>
                  </td>
                  <td>
                    <span className={className}>
                      {changeIcon && <FontAwesomeIcon icon={changeIcon} />}{" "}
                      {keyword.change}
                    </span>
                  </td>
                  <td>{keyword.previousPosition}</td>
                  <td>{keyword.bestPosition}</td>
                  <td>{keyword.initialPosition}</td>
                  <td>{keyword.lastChangeDate}</td>
                  <td>{keyword.MejorPos}</td>
                  <td>
                    <button onClick={() => handleDelete(keyword.keyword)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button onClick={() => handleTag(keyword.IdKeyword)}>
                      <FontAwesomeIcon icon={faTag} />
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="empty-row">
                No hay datos disponibles
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default KeywordTable;

// src/framework/drivers/App.js
import FirebaseKeywordRepository from "../data/repository/FirebaseKeywordRepository";
import FirebaseProjectRepository from "../data/repository/FirebaseProjectRepository";
import DeleteKeyword from "../domain/keyword/usecases/DeleteKeyword";
import AddKeywords from "../domain/keyword/usecases/AddKeywords";
import UpdateKeywordsUseCase from "../domain/keyword/usecases/UpdateKeywordsUseCase";
import GetKeywordsByProjectId from "../domain/keyword/usecases/GetKeywordsByProjectId";

import ChangesRepository from "../data/repository/ChangesRepository";
import GetChangesByProjectId from "../domain/changes/usecases/GetChangesByProjectId";

import GetProjectsUseCase from "../domain/project/usecases/GetProjectsUseCase";
import GetProjectByIdUseCase from "../domain/project/usecases/GetProjectByIdUseCase";
import DeleteProjectUseCase from "../domain/project/usecases/DeleteProjectUseCase";

import { db } from "./firebase";

const keywordRepository = new FirebaseKeywordRepository(db);
const getKeywordsByProjectIdUseCase = new GetKeywordsByProjectId(
  keywordRepository
);
const addKeywordsUseCase = new AddKeywords(keywordRepository);
const updateKeywordsUseCase = new UpdateKeywordsUseCase(keywordRepository);
const deleteKeywordUseCase = new DeleteKeyword(keywordRepository);

const changesRepository = new ChangesRepository(db);
const getChangesByProjectIdUseCase = new GetChangesByProjectId(
  changesRepository
);

const projectsRepository = new FirebaseProjectRepository(db);
const getProjects = new GetProjectsUseCase(projectsRepository);
const getProjectByIdUseCase = new GetProjectByIdUseCase(projectsRepository);
const deleteProjectUseCase = new DeleteProjectUseCase(projectsRepository);

export {
  getKeywordsByProjectIdUseCase,
  addKeywordsUseCase,
  updateKeywordsUseCase,
  deleteKeywordUseCase,
  getChangesByProjectIdUseCase,
  getProjects,
  getProjectByIdUseCase,
  deleteProjectUseCase,
};

export class Keyword {
    constructor(id, keyword, currentPosition, previousPosition, bestPosition, initialPosition, lastChangeDate) {
      this.id = id;
      this.keyword = keyword;
      this.currentPosition = currentPosition;
      this.change = Math.abs(previousPosition - currentPosition);
      this.previousPosition = previousPosition;
      this.bestPosition = bestPosition;
      this.initialPosition = initialPosition
      this.lastChangeDate = lastChangeDate;
    }
  }
class GetChangesByProjectId {
  constructor(changesRepository) {
    this.changesRepository = changesRepository;
  }

  async execute(projectId) {
    if (!projectId) {
      throw new Error('Invalid projectId');
    }

    return  Object.values(await this.changesRepository.getChangesByProjectId(projectId));
  }
}

export default GetChangesByProjectId;

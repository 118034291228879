// src/hooks/UseChanges.js
import { useState, useEffect } from "react";
import { getChangesByProjectIdUseCase } from "../../framework/DependencyInjector"; // Importa el nuevo caso de uso

export const useChanges = (projectId) => {
  const [changes, setChanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChanges = async () => {
      try {
        const changesData = await getChangesByProjectIdUseCase.execute(projectId);
        setChanges(changesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChanges();
  }, [projectId]);

  const refreshChanges = async () => {
    try {
      const changesData = await getChangesByProjectIdUseCase.execute(projectId);
      setChanges(changesData);
    } catch (err) {
      setError(err.message);
    }
  };

  return { changes, loading, error, refreshChanges };
};

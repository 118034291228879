import React, { useState } from "react";
import { parseCSVFile } from "../../../utils/csvHandler";
import { addKeywordsUseCase } from "../../../../framework/DependencyInjector";
import "./Popup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { auth } from "../../../../framework/firebase";

const AddKeywordPopup = ({ onClose, onKeywordsAdded, project }) => {
  const [keywords, setKeywords] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [dragActive, setDragActive] = useState(false); // Nuevo estado para manejar el arrastre

  const handleSubmit = async (e) => {
    e.preventDefault();
    let keywordArray = keywords.split(",").map((kw) => kw.trim());

    try {
      if (file) {
        const fileKeywords = await parseCSVFile(file);
        keywordArray = [...keywordArray, ...fileKeywords];
      }

      const keywordsToAdd = keywordArray.filter(Boolean);

      await Promise.all(
        keywordsToAdd.map(async (keyword) => {
          await addKeywordsUseCase.execute(
            auth.currentUser.uid,
            project,
            keyword
          );
        })
      );

      onKeywordsAdded();
      onClose();
    } catch (error) {
      console.error("Hubo un error al añadir las keywords:", error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          <p className="title">AÑADIR PALABRAS CLAVE AL PROYECTO</p>
          <input
            type="text"
            placeholder="Palabras clave"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
          <div className="addFile">
            <p>Importa las palabras clave desde un archivo archivo .csv:</p>
            <div
              className={`file-drop-area ${dragActive ? "active" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <FontAwesomeIcon icon={faCloudUpload} />
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{ display: "none" }} // Ocultamos el input nativo
                id="fileUpload"
              />
              <label htmlFor="fileUpload" className="file-upload-label">
                Selecciona o arrastra archivos aquí
              </label>
            </div>
          </div>
          <div className="buttons-container">
            <button type="button" onClick={onClose} className="cancelButton">
              Cancelar
            </button>
            <button type="submit" className="cta-button">
              Añadir keywords
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddKeywordPopup;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "./StatItem.css";

const getDifferenceDisplay = (change) => {
  let className = "";
  let changeIcon = null

  if (change > 0) {
    className = "negative-diff";
    changeIcon = faArrowDown
  } else if (change < 0) {
    className = "positive-diff";
    changeIcon = faArrowUp
  }

  return { className, changeIcon };
};

const StatItem = ({ icon, classIcon, title, value, trend, chart }) => {
  const { className, changeIcon } = getDifferenceDisplay(trend);
  return (
    <div className="stat">
      <FontAwesomeIcon icon={icon} className={`stats-icon ${classIcon}`} />
      <h3 className="stat-title">{title}</h3>
      <div className="stat-info">
        <p className="stat-value">{value}</p>
        <p className={className}> {changeIcon && <FontAwesomeIcon icon={changeIcon} />} {Math.abs(trend)}</p>        
      </div>
      <div className="stat-chart">{chart}</div>
    </div>
  );
};

export default StatItem;

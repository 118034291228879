// src/ui/Login.js
import React from "react";
import { auth } from "../../../framework/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../../../assets/images/google-icon.svg";

const Login = () => {
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      console.error("Error al iniciar sesión: ", error);
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-box">
          <h1>Iniciar Sesión</h1>
          <button className="google-login-button" onClick={handleLogin}>
            <img src={logo} alt="Logo" />
            Iniciar sesión con Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;

// src/data/repositories/FirebaseKeywordRepository.js
import { ref, get, child, push, set, update, remove } from "firebase/database";
import { Keyword } from "../../domain/keyword/model/Keyword";

export default class FirebaseKeywordRepository {
  constructor(db) {
    this.db = db;
  }

  async getKeywordsByProjectId(userId, projectId) {
    const snapshot = await get(
      ref(this.db, `users/${userId}/projects/${projectId}/keywords`)
    );
    if (snapshot.exists()) {
      const data = snapshot.val();
      const keywordsArray = Object.entries(data).map(([id, keywordData]) => {
        return new Keyword(
          id,
          keywordData.keyword,
          keywordData.currentPosition,
          keywordData.previousPosition,
          keywordData.bestPosition,
          keywordData.initialPosition,
          keywordData.lastChangeDate,
          keywordData.initialPosition
        );
      });
      return keywordsArray;
    } else {
      return [];
    }
  }

  async addKeywords(userId, project, keyword) {
    try {
      const proyectoRef = ref(
        this.db,
        `users/${userId}/projects/${project.id}/keywords`
      );
      const updates = {};

      // Validación de keyword
      if (!keyword || typeof keyword !== "string") {
        throw new Error(`Keyword inválida: ${keyword}`);
      }

      if (/[\.\#\$\[\]]/.test(keyword)) {
        console.error("Keyword contains invalid characters:", keyword);
        throw new Error(`Keyword contiene caracteres inválidos: ${keyword}`);
      }

      const keywordRef = child(proyectoRef, keyword);
      const snapshot = await get(keywordRef);

      if (!snapshot.exists()) {
        const googlePosition = await this.getDomainPosition(
          project.apikey,
          project.domain,
          keyword
        );

        updates[keyword] = {
          keyword: keyword,
          currentPosition: googlePosition,
          previousPosition: googlePosition,
          bestPosition: googlePosition,
          lastChangeDate: new Date().toLocaleDateString(),
          initialPosition: googlePosition,
        };
      }

      await update(proyectoRef, updates);
      return { message: "Keyword creada con éxito" };
    } catch (error) {
      console.error("Error adding keyword: ", error);
      throw new Error("Failed to add keyword");
    }
  }

  async updateKeywordsForAllProjects(userId) {
    const projectsRef = ref(this.db, `users/${userId}/projects`);
    const snapshot = await get(projectsRef);
  
    if (snapshot.exists()) {
      const projectsData = snapshot.val();
      const updates = {};
      const today = new Date().toLocaleDateString(); // Obtener la fecha actual en formato "día/mes/año"
  
      for (const [projectId, projectData] of Object.entries(projectsData)) {
        // Verificar si el proyecto ya ha sido actualizado hoy
        if (projectData.lastUpdate === today) {
          console.log(`El proyecto ${projectData.name} ya está actualizado hoy.`);
          continue; // Saltar la actualización de este proyecto
        }
  
        const keywordsRef = ref(this.db, `users/${userId}/projects/${projectId}/keywords`);
        const keywordsSnapshot = await get(keywordsRef);
  
        if (keywordsSnapshot.exists()) {
          const keywords = keywordsSnapshot.val();
  
          for (const keyword in keywords) {
            const googlePosition = await this.getDomainPosition(
              projectData.apiKey,
              projectData.domain,
              keywords[keyword].keyword
            );
  
        
            const currentPosition = keywords[keyword].currentPosition;
            const bestPosition = googlePosition < keywords[keyword].bestPosition ? googlePosition : keywords[keyword].bestPosition;
  
            updates[`users/${userId}/projects/${projectId}/keywords/${keyword}`] = {
              ...keywords[keyword],
              previousPosition: currentPosition,
              bestPosition: bestPosition,
              currentPosition: googlePosition,
              lastChangeDate: new Date().toLocaleDateString(),
            };
          }
  
          updates[`users/${userId}/projects/${projectId}/lastUpdate`] = today;
        }
      }
  
      await update(ref(this.db), updates);
      return { message: "Keywords actualizadas con éxito" };
    } else {
      throw new Error("No se encontraron proyectos");
    }
  }
  

  async deleteKeyword(userId, projectId, keywordId) {
    if (!userId || !projectId || !keywordId) {
      throw new Error("Invalid userId, projectId, or keywordId");
    }
    const keywordRef = ref(
      this.db,
      `users/${userId}/projects/${projectId}/keywords/${keywordId}`
    );
    await remove(keywordRef);
  }

  async getDomainPosition(apiKey, domain, keyword) {
    try {
      const response = await fetch(
        `https://capturetherank.com/goosearch.php?apiKey=${apiKey}&domain=${domain}&keyword=${keyword}`
      );
      console.log(
        `https://capturetherank.com/goosearch.php?apiKey=${apiKey}&domain=${domain}&keyword=${keyword}`
      );

      if (!response.ok) {
        throw new Error("Error al obtener la posición del dominio");
      }
      const data = await response.json();
      return data.position;
    } catch (error) {
      console.error("Error fetching domain position:", error);
      return 101; // Puedes devolver 101 o cualquier otro valor por defecto en caso de error
    }
  }
}

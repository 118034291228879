// src/domain/usecases/DeleteKeyword.js
class DeleteKeyword {
  constructor(keywordRepository) {
    this.keywordRepository = keywordRepository;
  }

  async execute(uid, projectId, keywordId) {
    if (!projectId || !keywordId) {
      throw new Error('Invalid projectId or keywordId');
    }

    await this.keywordRepository.deleteKeyword(uid, projectId, keywordId);
  }
}

export default DeleteKeyword;

//src\domain\project\usecases\GetProjectsUseCase.js
class GetProjectsUseCase {
  constructor(projectRepository) {
    this.projectRepository = projectRepository;
  }

  async execute() {
    return  Object.values(await this.projectRepository.getProjects());
  }
}

export default GetProjectsUseCase;
